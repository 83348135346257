<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                        </div>


                        <div class="box-body !p-0 mybodyheight400px">




                            <div class="grid grid-cols-12 gap-4">

                                <!--div class="alert alert-warning">
                                    <div class="warning">
                                        <h1>⚠️ AVERTISSEMENT IMPORTANT ⚠️</h1>
                                        <p>
                                            L'opération d'éclatement de caisse est <strong>irréversible</strong>.
                                            Elle couvre l'ensemble des opérations <strong>du début de l'opération référentielle</strong> jusqu'à <strong>la fin de toutes les opérations</strong>.
                                        </p>
                                        <p>
                                            Si des journées clôturées sont enregistrées après cette journée, <strong>cela impactera également ces opérations</strong>.
                                        </p>
                                        <p>
                                            Veuillez confirmer uniquement si vous êtes sûr de vouloir procéder.
                                            <strong>Si vous ne comprenez pas cette opération, veuillez vous rapprocher de votre administrateur.</strong>
                                        </p>
                                    </div>
                                </div-->

                                <div class="xl:col-span-12 col-span-12">

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Caisse</label>
                                        <select @change="loadalloperationbyjourne" v-model="Items.c" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm
                                                                    !py-2 !px-3">
                                            <option :value="null">Sélectionner une caisse</option>
                                            <option v-for="(caisse,index) of filterdata" :value="caisse" :key="index">{{caisse.name}}</option>

                                        </select>
                                    </div>


                                </div>


                                <div class="xl:col-span-12 col-span-12">
                                    <div class="space-y-2">
                                        <label >La journée  du </label>
                                        <input @change="loadalloperationbyjourne" class="w-full" type="date"   v-model="Items.db" placeholder="Date début">
                                    </div>

                                </div>


                                <div class="xl:col-span-12 col-span-12">

                                    <div class="space-y-2">
                                        <label >Commencé à partir de l'opération du &nbsp;</label>
                                        <select  v-model="Items.operation" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm
                                                                    !py-2 !px-3">
                                            <option :value="null">Sélectionner la première opération de la journée <template v-if="Items.db">du {{Items.db}}</template></option>
                                            <option v-for="(operation,index) of alloperationbyjournee" :value="operation" :key="index">{{operation.cnumdoc+' - crée le '+operation.created_at}}</option>

                                        </select>
                                    </div>
                                </div>



                                <div class="xl:col-span-12 col-span-12">
                                    <div class="space-y-2">
                                        <label >La nouvelle journée </label>
                                        <input class="w-full" type="date"   v-model="Items.newdb" placeholder="Date début">
                                    </div>

                                </div>




                                <div class="xl:col-span-12 col-span-12">

                                    <center>
                                        <button @click="btnouinonmiseajour" style="text-transform: uppercase"  type="button" class="ti-btn bg-primary text-white m-1">
                                            mise à jour des états de paiement
                                        </button>
                                    </center>

                                </div>


                            </div>

                        </div>


                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>

    <okouinonmajetatdepaiement ref="okouinonmajetatdepaiement"></okouinonmajetatdepaiement>

    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>


</template>

<script>
    import okouinonmajetatdepaiement from "@/components/views/pages/setting/utilitaires/caisse/okouinonmajeclatementoperation";


    const axios =require('axios');


    import {isValidDateString, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {



        },
        computed:{


            filterdata(){

                let mvalue=this.datatablesoldecaisse;


                if (this.pidcaisse!==''){
                    let t=this;
                    mvalue = mvalue.filter( function (post) {
                        return    post.pid.toString().toLowerCase().includes(t.pidcaisse.toString().toLowerCase())

                    })

                }



                return  mvalue ;
            },

        },
        components: {
            okouinonmajetatdepaiement
        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datatablesoldecaisse:[],
                alloperationbyjournee:[],
                messageerror:"",
                messagesucces:"",
                pidcaisse:"",
                Items:{},

            };
        },
        methods: {



            async load(){



                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/onoeofienfneioefezp849418119815191981dsdsc1dscds9/'+randomString(200)+'/profil/'+this.$store.state.appsociete+'/allprofiluser/'+randomString(200);
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            t.datatable=[];
                            t.datatable=res.data.m;
                            t.dataoptions=res.data.moptions;
                            //t.alldata=res.data.alldata;
                             t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];
                           // t.alldata=[];
                            t.dataoptions=res.data.moptions;

                            t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },



            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },


            async loadalloperationbyjourne() {

                if (this.Items.c===null){
                    this.Items.operation=null
                    this.alloperationbyjournee=[];
                    //toastr['error']( "Veuillez sélectionner une caisse. ","<b></b>");
                    return
                }

                if (!isValidDateString(this.Items.db) ){
                    this.Items.operation=null
                    this.alloperationbyjournee=[];
                    return
                }

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/utilitaire/loadopjournee/'+this.$store.state.appsociete+'/'+this.Items.c.pid+'/'+this.Items.db+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.alloperationbyjournee=response.data.m


                        }
                        else{


                            t.alloperationbyjournee=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },



            async loadsoldebanque() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/587999fffsssdfffsuiviedescaisses784517788/'+randomString(280)+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.datatablesoldecaisse=response.data.cc


                        }
                        else{


                            t.datatablesoldecaisse=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },



            btnouinonmiseajour(){

                if (this.Items.c===null){

                    toastr['error']( "Veuillez sélectionner une caisse. ","<b></b>");
                    return
                }

                if (this.Items.operation===null){

                    toastr['error']( "Veuillez sélectionner une opération référentielle. ","<b></b>");
                    return
                }

                if (this.Items.newdb===null || !isValidDateString(this.Items.newdb)){

                    toastr['error']( "Veuillez sélectionner la nouvelle journée... ","<b></b>");
                    return
                }

                let titlemodal=`VOULLEZ-VOUS VRAIMENT ECLATER LES OPERATION DE LA CAISSE '${this.Items.c.name}'
                  JOURNEE DU ${this.Items.db} A PARTIR DE L'OPRERATION ${this.Items.operation.cnumdoc} SUR LA JOURNEE DU ${this.Items.newdb}  ?`;

                this.$refs.okouinonmajetatdepaiement.show(titlemodal,this.Items);


            },

            show(pidcaisse=""){

                this.Items={
                    c:null,
                    operation:null,
                        db:null,
                        newdb:null,

                }
                this.messagesucces="";
                this.messageerror="";
                this.pidcaisse=pidcaisse;
                this.titlemodal="Mise à jour des éclatements des opérations caisse sur une journée"
                /* this.datatable=listdata
                 */

               // this.load()

                this.loadsoldebanque()


                this.isdisplay=true;



            },






        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .warning {
        border: 2px solid #f39c12;
        background-color: #fdf2e9;
        color: #e67e22;
        padding: 2px;
        border-radius: 5px;
        text-align: center;
    }
    .warning h1 {
        margin: 0 0 10px 0;
        font-size: 16px;
    }
    .warning p {
        margin: 10px 0;
        line-height: 1.5;
    }
    .warning strong {
        color: #c0392b;
    }


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 60%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>