<template>


    <nav class="menutop bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Menu</span>
            </a>
            <button @click="toggleNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" :aria-expanded="isNavbarOpen.toString()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div :class="{ 'hidden': !isNavbarOpen, 'block': isNavbarOpen }" class="w-full md:block md:w-auto" id="navbar-dropdown">
                <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Caisse<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li >
                                    <a @click="btnmodalindexcaisse" class="ti-dropdown-item" href="javascript:void(0);">
                                        Créer une caisse
                                    </a>
                                </li>

                                <li >
                                    <a @click="btnmodalindexbanque" class="ti-dropdown-item" href="javascript:void(0);">
                                        Créer une banque
                                    </a>
                                </li>

                                <li >
                                    <a @click="btnmodalindexaccesscaisse"  class="ti-dropdown-item" href="javascript:void(0);">
                                        Donner / Retirer un accès sur une caisse
                                    </a>
                                </li>




                            </ul>
                        </div>

                    </li>

                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Utilisateur<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li >
                                    <a @click="btnmodalprofiluser" class="ti-dropdown-item" href="javascript:void(0);">
                                        Créer un profil / accès pages
                                    </a>
                                </li>


                                <li >
                                    <a @click="btnmodalindexutilisateur" class="ti-dropdown-item" href="javascript:void(0);">
                                        Créer un utilisateur
                                    </a>
                                </li>




                            </ul>
                        </div>

                    </li>


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                               aria-expanded="false">
                                Utilitaires<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">



                                <li >
                                    <a @click="btnutilitairemiseajourpointsurletatdepaiement" class="ti-dropdown-item" href="javascript:void(0);">
                                        Caisse / Mise à jour des points de caisse sur les états de paiement
                                    </a>
                                </li>

                                <li >
                                    <a @click="btnutilitairemiseajoureclatementdesoperationscaisse" class="ti-dropdown-item" href="javascript:void(0);">
                                        Caisse / Mise à jour éclatement des opération de caisse sur une date à parti d'un référentiel d'opération
                                    </a>
                                </li>

                                <li >
                                    <a @click="btnutilitairemiseajouretatdepaiement" class="ti-dropdown-item" href="javascript:void(0);">
                                        Caisse / Mise à jour des états de paiement
                                    </a>
                                </li>




                            </ul>
                        </div>

                    </li>




                </ul>
            </div>
        </div>
    </nav>


    <modalindexcaisse ref="modalindexcaisse"></modalindexcaisse>
    <modalindexbanque ref="modalindexbanque"></modalindexbanque>
    <modalindexaccesscaisse ref="modalindexaccesscaisse"></modalindexaccesscaisse>
    <modalindexutilisateur ref="modalindexutilisateur"></modalindexutilisateur>
    <modalprofiluser ref="modalprofiluser"></modalprofiluser>


    <utilitairemiseajouretatdepaiement ref="utilitairemiseajouretatdepaiement"></utilitairemiseajouretatdepaiement>
    <utilitairemiseajourpointsurletatdepaiement ref="utilitairemiseajourpointsurletatdepaiement"></utilitairemiseajourpointsurletatdepaiement>
    <utilitairemiseajoureclatementdesoperationscaisse ref="utilitairemiseajoureclatementdesoperationscaisse"></utilitairemiseajoureclatementdesoperationscaisse>


</template>


<script>

    import modalindexcaisse from "@/components/views/pages/setting/caisse/index";
    import modalindexbanque from "@/components/views/pages/setting/banque/index";
    import modalindexaccesscaisse from "@/components/views/pages/setting/accesscaisse/index";
    import modalindexutilisateur from "@/components/views/pages/setting/utilisateur/index";
    import modalprofiluser from "@/components/views/pages/setting/profiluser/index";
    import utilitairemiseajouretatdepaiement from "@/components/views/pages/setting/utilitaires/caisse/modal_miseajouretatdepaiement";
    import utilitairemiseajoureclatementdesoperationscaisse from "@/components/views/pages/setting/utilitaires/caisse/modal_miseajoureclatementdesoperationscaisse";
    import utilitairemiseajourpointsurletatdepaiement from "@/components/views/pages/setting/utilitaires/caisse/modal_miseajourPointsurletatdepaiement";






    export default {
        /* eslint-disable */
        name: "mymenu",
        data() {
            return {
                isNavbarOpen: false,
                isDropdownOpen: true
            };
        },
        components: {
            modalindexcaisse,
            modalindexbanque,
            modalindexaccesscaisse,
            modalindexutilisateur,
            modalprofiluser,
            utilitairemiseajouretatdepaiement,
            utilitairemiseajoureclatementdesoperationscaisse,
            utilitairemiseajourpointsurletatdepaiement,

        },
        methods: {


            btnutilitairemiseajourpointsurletatdepaiement(){

                this.$refs.utilitairemiseajourpointsurletatdepaiement.show();
            },

            btnutilitairemiseajouretatdepaiement(){

                this.$refs.utilitairemiseajouretatdepaiement.show();
            },



            btnutilitairemiseajoureclatementdesoperationscaisse(){

                this.$refs.utilitairemiseajoureclatementdesoperationscaisse.show();
            },

            btnmodalprofiluser(){

                this.$refs.modalprofiluser.show();
            },

            btnmodalindexcaisse(){

                this.$refs.modalindexcaisse.show();
            },

            btnmodalindexutilisateur(){

                this.$refs.modalindexutilisateur.show();
            },

            btnmodalindexbanque(){

                this.$refs.modalindexbanque.show();
            },

            btnmodalindexaccesscaisse(){

                this.$refs.modalindexaccesscaisse.show();
            },



            toggleNavbar() {
                this.isNavbarOpen = !this.isNavbarOpen;
            },
            toggleDropdown() {

                this.isDropdownOpen = !this.isDropdownOpen;

            }
        }
    };
</script>

<style scoped>

     /*@import url('https://cdn.tailwindcss.com');
    @import url("@/assets/css/tailwindv2_0_1.css");*/

    .menutop{
        /*margin-top: 15px!important;*/
        margin-top: 4% !important;
    }

     button:focus {

         outline: 0px auto -webkit-focus-ring-color!important;
     }

     @media screen and (max-width: 800px) {
         .menutop{
             /*margin-top: 15px!important;*/
             margin-top: 12.5% !important;
         }
     }


</style>