<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"> MISE A JOUR
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">


                        <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                            <div v-html="messagesucces">

                            </div>
                        </div>

                        <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">


                                <div v-html="messageerror">

                                </div>

                        </div>



                        <strong>
                            {{titlemodal}}
                        </strong>
                        <br>
                        <p style="color: red; font-weight: bolder">
                            Cette opération est irréversible et affectera vos états de paiement ainsi que le solde de votre caisse pour la période sélectionnée. Veuillez vérifier attentivement vos données et, si possible,
                            imprimez l'état des paiements de la période avant de poursuivre.
                        </p>
                        <br>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="xl:col-span-1 col-span-1">
                                <input class="form-check-input me-2" type="checkbox" v-model="checkok">
                            </div>

                            <div class="xl:col-span-10 col-span-10">

                                <p class="mb-0 text-muted" style="color: red;font-weight: bolder">Cochez pour confirmer que vous comprenez les risques.</p>

                            </div>


                        </div>

                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Non
                    </button>
                    <button :disabled="!checkok" @click="btnsuppression" :class="checkok?'ti-btn-primary-full':'ti-btn-info-full'"
                            class="ti-btn " href="javascript:void(0);" :style="checkok?'background: greenyellow':'' ">
                       Oui
                    </button>

                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    //import allfunction from "@/allfunction";
    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",
        watch: {


        },

        computed:{




        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                datavalue:"",
                titlemodal:"",
                Items:{},
                typedoc:"",
                checkok:false,
                typededocument:"",
                messageerror:"",
                messagesucces:"",
            };
        },
        methods: {

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },


            show(titlemodal,Items){
                this.messagesucces="";
                this.messageerror="";
                this.titlemodal=titlemodal
                this.Items=Items
                this.checkok=false
                           this.isdisplay=true;

            },

            async  btnsuppression(){

                if (this.checkok===false){return }
                this.$store.dispatch('lancerchargement',true)
                const t = this;

                let url=process.env.VUE_APP_API_URL+'/utilitaire/majpointdecaisseetatpaiement/'+this.$store.state.appsociete+'/'+this.Items.c.pid+'/'+this.Items.db+'/'+this.Items.df+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){

                            t.messagesucces="";
                            t.messageerror="";

                            t.messagesucces=res.data.message;

                            toastr['success']( t.messagesucces,"<b></b>");


                            t.close()

                        }
                        else{

                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b>Erreur</b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))


            },








        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .form-check-input {
        height: 1.9rem;
        width: 1.9rem;
        border-radius: 0.25rem;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(var(--input-border));
        --tw-bg-opacity: 1;
        background-color: coral;
    }

    .modalstyle{
        max-width: 50%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }

</style>